import { Router } from "./router";
import { BrowserView, MobileView } from "react-device-detect";

function App() {
  return (
    <>
      <BrowserView>
        <Router />;
      </BrowserView>
      <MobileView>
        <h1 style={{ color: "white", textAlign: "center", marginTop: "50%" }}>
          Sorry, Currently we suport only Desktop...!
        </h1>
      </MobileView>
    </>
  );
}

export default App;
