import { Button, Tag } from "antd";
export const Users = (handleUpdateToken, hanleStop) => [
  {
    title: "SL",
    dataIndex: "username",
    key: "username",
    width: "5%",
    render: (id, _, idx) => idx + 1,
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width: "25%",
    render: (data) => data.toUpperCase(),
  },
  {
    title: "UserId",
    dataIndex: "userId",
    width: "25%",
  },
  {
    title: "Current Status",
    dataIndex: "isLive",
    key: "isLive",
    render: (data) => {
      return data ? (
        <Tag color="green">online</Tag>
      ) : (
        <Tag color="red">Offline</Tag>
      );
    },
  },
  {
    title: "P&L",
    dataIndex: "total",
    key: "total",
    render: (data) => {
      return data ? (
        <span style={{ color: data < 0 ? "red" : "green" }}>
          {data.toLocaleString("en-IN")}
        </span>
      ) : (
        "-"
      );
    },
  },

  {
    title: "Update Token",
    dataIndex: "isLive",
    key: "isLive",
    render: (data, item) => {
      return (
        <Button
          disabled={data}
          onClick={() => {
            handleUpdateToken(item);
          }}
        >
          Update Token
        </Button>
      );
    },
  },
  {
    title: "Stop Trade",
    dataIndex: "isLive",
    key: "isLive",
    render: (data, item) => {
      return (
        <Button
          disabled={!data}
          onClick={() => {
            hanleStop(item);
          }}
        >
          Stop Trade
        </Button>
      );
    },
  },
];
