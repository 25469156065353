import { LoginModel } from "./../components";
import { login } from "./../API/dispatch";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Spin } from "antd";

import "./style/gitkey.css";

export function Login({ setAuth }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (event) => {
    setLoading(true);
    const data = await login("/auth/login", event, setLoading);
    if (data?.success) {
      setAuth(true);
      navigate("/ai/users");
      setLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="container">
          <div class="content">
            <h2>StockBot (Seller)</h2>
            <h2>StockBot (Seller)</h2>
          </div>
        </div>

        <div className="container-content">
          <LoginModel onFininsh={handleFinish} />
        </div>
      </Spin>
    </>
  );
}

export default Login;
